(function () {
    function startHandler(){
        var el = $("fieldset[data-handle='color']");
        
        // will override the ?width of the images below during the hover over preview if set to any value greater than 0
        var imagePreviewWidth = 0;

        // Will prefix the color with "<prefix> <color name>", set to "" if you don't want this;
        var colorNamePrefix = "";

        // Make the color names uppercase
        var upperCaseColorNames = true;

        var configuration = {
            '/collections/cocoon-for-dogs-and-cats/products/sweet-goodbye-cocoon-premium-wool-pet-burial-cremation-ceremony-kit': {
                colorOptions: [
                    ['aqua', '#98c0b7', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Aqua.jpg?v=1632816745&width=1200', 'Aqua'],
                    ['lavender', '#a991bb', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-LightPurple.jpg?v=1632816745&width=1200', 'Lavender'],
                    ['light-blue', '#567aba', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-LightBlue.jpg?v=1632816745&width=1200', 'Light Blue'],
                    ['natural', '#ccc5bb', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Natural.jpg?v=1632816745&width=1200', 'Natural'],
                    ['pink', '#af436d', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Pink.jpg?v=1632816745&width=1200', 'Pink'],
                    ['teal', '#497982', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Teal.jpg?v=1632816745&width=1200', 'Teal'],
                    ['indigo', '#2c3b79', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Indigo.jpg?v=1632816745&width=1200', 'Indigo'],
                    ['magenta', '#672c61', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Magenta.jpg?v=1632816745&width=1200', 'Magenta'],
                    ['grey', '#7f7f7f', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Parent-Grey.jpg?v=1632816745&width=1200', 'Grey']
                ]
            },
            '/collections/cocoon-for-all-sized-pets/products/sweet-goodbye-cocoon-classic-cotton-pet-burial-cremation-ceremony-kit': {
                colorOptions: [
                    ['black-teal-green', 'https://sweetgoodbyeforpets.com/cdn/shop/files/black-teal_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-Black-TealGreen.jpg?v=1632892740&width=1200', 'Black/Teal'],
                    ['white-light-blue', 'https://sweetgoodbyeforpets.com/cdn/shop/files/white-light-blue_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-White-LightBlue_ee897070-466c-46d3-88a8-4735cd2742df.jpg?v=1632893168&width=1200', 'White/Light Blue'],
                    ['black-magenta', 'https://sweetgoodbyeforpets.com/cdn/shop/files/black-magenta_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-Black-Magenta.jpg?v=1632815962&width=1200', 'Black/Magenta'],              
                    ['white-pink', 'https://sweetgoodbyeforpets.com/cdn/shop/files/white-pink_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-White-Pink.jpg?v=1632893342&width=1200', 'White/Pink'],
                    ['red-blue', 'https://sweetgoodbyeforpets.com/cdn/shop/files/red-blue_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-Red-Blue.jpg?v=1632892871&width=1200', 'Red/Blue'],
                    ['white-natural', 'https://sweetgoodbyeforpets.com/cdn/shop/files/white-natural_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-White-Natural.jpg?v=1632893254&width=1200', 'White/Natural'],
                    ['white-aqua', 'https://sweetgoodbyeforpets.com/cdn/shop/files/white-aqua_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-White-Aqua.jpg?v=1632892946&width=1200', 'White/Aqua'],
                    ['black-pink', 'https://sweetgoodbyeforpets.com/cdn/shop/files/black-pink_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-Black-Pink.jpg?v=1632892404&width=1200', 'Black/Pink'],
                    ['white-lavender', 'https://sweetgoodbyeforpets.com/cdn/shop/files/white-lavender_50x50.png', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cocoon-Cotton-Parent-White-Lavender.jpg?v=1632893068&width=1200', 'White/Lavender']
                ]
            },
            '/collections/cloud®-for-smaller-pets/products/sweet-goodbye-cloud®-pet-burial-cremation-ceremony-kit': {
                colorOptions: [
                    ['aqua', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Aqua-L.jpg?v=1632893440&width=1200', 'Aqua'],
                    ['lavender', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-LightPurple-L.jpg?v=1632893610&width=1200', 'Lavender'],
                    ['light-blue', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-LightBlue-L_d328987b-bc92-49ec-a322-3507d5e775d3.jpg?v=1632893661&width=1200', 'Light Blue'],
                    ['natural', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Natural-L.jpg?v=1632893760&width=1200', 'Natural'],
                    ['pink', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Pink-L.jpg?v=1632893877&width=1200', 'Pink'],
                    ['indigo', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Indigo-L.jpg?v=1632893560&width=1200', 'Indigo'],
                    ['magenta', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Magenta-L.jpg?v=1632893711&width=1200', 'Magenta'],
                    ['grey', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Grey-L.jpg?v=1632893515&width=1200', 'Grey'],
                    ['teal', 'thumbnail', 'https://sweetgoodbyeforpets.com/cdn/shop/products/Cloud-Closed-Teal-L.jpg?v=1632893515&width=1200', 'Teal']
                ]
            }
        };

        var currentPath = window.location.pathname;

        function addRIFTColorSwatches(baseURL, colorOptionsSimplified) {

            var originalColorName = $("[data-variant-color-label]").text();

            var colorOptions = [{
                colorCode: 'X',
                colorName: 'X',
                friendlyName: 'X',
                url: 'X',
                imgURL: '',
            }];

            colorOptions.pop();


            for (var i = 0; i < colorOptionsSimplified.length; ++i) {
                var colorOption = colorOptionsSimplified[i];
                var colorName = colorOption[0];
                var colorCode = colorOption[1];
                var previewURL = colorOption[2];
                var friendlyName = colorOption[3];

                if (currentPath.endsWith(colorName)) {
                    // skip current color
                    continue;
                }

                colorOptions.push({
                    colorCode: colorCode,
                    colorName: colorName,
                    url: baseURL + "-" + colorName,
                    imgURL: previewURL,
                    friendlyName: friendlyName
                });
            }

            $("[data-rift-colorswatch]").remove();

            var image = $("[data-product-single-media-group]");

            var imageHouse = null;
            if (document.getElementById("RIFTColorSwatches-ImageHouse") == null) {
                imageHouse = $("<IMG>", {
                    'style': 'position: fixed; display: none;',
                    'id': "RIFTColorSwatches-ImageHouse"
                });
                $("body").append(imageHouse);
            } else {
                imageHouse = $("#RIFTColorSwatches-ImageHouse");
            }

            

            function handleRIFTSwatchClick(swatchData) {
                var colorName = swatchData.colorName;
                window.location = baseURL + "-" + colorName;
            }

            function handleRIFTSwatchMouseover(swatchData) {
                var newURL = swatchData.imgURL;

                console.log(image[0].getBoundingClientRect());

                imageHouse.attr('src', newURL);
                imageHouse.css("top", image[0].getBoundingClientRect()['y']);
                imageHouse.css("left", image[0].getBoundingClientRect()['x']);
                imageHouse.css("width", image[0].getBoundingClientRect()['width']);
                imageHouse.css("height", image[0].getBoundingClientRect()['height']);
                imageHouse.css("display", 'block');
                //$(imageHouse).fadeIn();
                $("[data-variant-color-label]").text((colorNamePrefix == null || colorNamePrefix == "" ? "" : colorNamePrefix + " ") + (upperCaseColorNames ? swatchData.friendlyName.toUpperCase() : swatchData.friendlyName));
            }

            function handleRIFTSwatchMouseout(swatchData) {
                //$(imageHouse).fadeOut();
                imageHouse.css("display", 'none');
                imageHouse.attr('src', '');
                $("[data-variant-color-label]").text(originalColorName);
            }

            for (var i = 0; i < colorOptions.length; ++i) {
                const colorOption = colorOptions[i];
                var colorCode = colorOption['colorCode'];
                var colorName = colorOption['colorName'];
                var imageURL = colorOption['imgURL'];

                if(imagePreviewWidth > 0){
                    if(imageURL.indexOf("?") !== -1){
                        imageURL = imageURL.split("?")[0];
                        imageURL = imageURL + "?v=1632816745&width=" + imagePreviewWidth;
                    }
                }

                var targetID = 'RIFTColorSwatchSelector-' + colorName;

                if (document.getElementById(targetID) != null) {
                    continue;
                }

                if (colorCode == 'thumbnail'){
                    colorCode = 'https://sweetgoodbyeforpets.com/cdn/shop/files/' + colorName + '_50x50.png';
                }

                var labelElement = $("<LABEL>", {
                    'class': 'variant__button-label color-swatch',
                    'style': (colorCode.startsWith('http') ? 'background-image: url(\'' : 'background-color: ') + colorCode + (colorCode.startsWith('http') ? '\')' : '') + '; cursor: pointer;',
                    'data-rift-colorswatch': ''
                });

                labelElement.mouseover(function () {
                    handleRIFTSwatchMouseover(colorOption);
                });

                labelElement.mouseout(function () {
                    handleRIFTSwatchMouseout(colorOption);
                });

                labelElement.click(function () {
                    handleRIFTSwatchClick(colorOption);
                });

                var imagePreloadID = 'riftImagePreload-' + colorName;
                if (document.getElementById(imagePreloadID) == null) {
                    var imagePreload = $("<IMG>", {
                        'src': imageURL,
                        'style': 'display: block; position: fixed; width: 1px; height: 1px; opacity: 0.01; left: -10; bottom: -10px;',
                        'rel': 'preload',
                        'id': imagePreloadID
                    });

                    $("body").append(imagePreload);
                }

                el.append(labelElement);
            }
        }

        for (var basePath in configuration) {
            if (!currentPath.toLowerCase().startsWith(basePath.toLowerCase()) && !currentPath.toLowerCase().startsWith(encodeURI(basePath).toLowerCase())) {
                continue;
            }
            var colorOptionsSimplified = configuration[basePath]['colorOptions'];
            addRIFTColorSwatches(basePath, colorOptionsSimplified);
        }
    }
    
    var loadCheckInterval = setInterval(function(){
        if($ == null){
            // JQuery is not loaded yet
            return;
        }
        clearInterval(loadCheckInterval);
        startHandler();
    }, 250);
})();